import App from '../App';

export default {
  path: '/auth/v3',
  component: App,
  children: [
    {
      path: 'login',
      name: 'auth-log-in',
      component: () => import('../pages/Login.vue'),
    },
    {
      path: 'verification-code',
      name: 'auth-verification-code',
      meta: {
        disableDirectUrl: true
      },
      component: () => import('../pages/VerificationCode.vue'),
    },
    {
      path: 'verification-code-not-received',
      name: 'auth-verification-code-not-received',
      component: () => import('../pages/VerificationCodeNotReceived.vue'),
    },
    {
      path: 'verification-missing-email',
      name: 'auth-verification-missing-email',
      meta: {
        disableDirectUrl: true
      },
      component: () => import('../pages/VerificationMissingEmail.vue'),
    },
    {
      path: 'reset-email',
      name: 'auth-reset-email',
      component: () => import('../pages/ResetEmail.vue'),
    },
    {
      path: 'reset-admins',
      name: 'auth-reset-admins',
      component: () => import('../pages/ResetAdmins.vue'),
    },
    {
      path: 'reset-sms',
      name: 'auth-reset-sms',
      component: () => import('../pages/ResetSms.vue'),
    },
    {
      path: 'reset/:login/:userId/:code',
      name: 'auth-reset',
      component: () => import('../pages/Reset.vue'),
      props: true,
    },
    {
      path: '*',
      component: () => import('../pages/404.vue'),
    }
  ],
};
