<template>
  <div class="b-main__inner">
    <div class="b-logo">
      <a href="/">
        <img :src="logoPath" :alt="logoAlt" />
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import hosts from '@/constants/hosts'

export default {
  computed: {
    logoPath() {
      if (hosts.ukrainianHosts.includes(location.host)) {
        return require('../assets/logika_logo.svg')
      }

      if (hosts.lectorixHosts.includes(location.host)) {
        return require('../assets/lectorix_logo.svg')
      }

      if (hosts.mexicanHosts.includes(location.host)) {
        return require('../assets/mexican_logo.svg')
      }

      if (hosts.indonesianHosts.includes(location.host)) {
        return require('../assets/indonesian_logo.svg')
      }

      return require('../assets/logo.svg')
    },
    logoAlt() {
      if (hosts.ukrainianHosts.includes(location.host)) {
        return 'Logika'
      }

      if (hosts.lectorixHosts.includes(location.host)) {
        return 'Lectorix'
      }

      if (hosts.mexicanHosts.includes(location.host)) {
        return 'Algonova'
      }

      if (hosts.indonesianHosts.includes(location.host)) {
        return 'Algonova'
      }

      return 'Algoritmika';
    }
  }
}
</script>
